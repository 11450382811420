import React from 'react';
import {
    Box,
    Typography,
    Stack,
    Button
} from '@mui/material';

import patratele from '../resources/shutterstock_1067708192.jpg';
import near from '../resources/partners/Logo NEAR.svg';
import filecoin from '../resources/partners/Logo Filecoin.svg';
import dfinity from '../resources/partners/Logo Dfinity.svg';
import polkadot from '../resources/partners/Logo Polkadot.svg';
import stellar from '../resources/partners/Logo Stellar.svg';
import hands from '../resources/Hands.jpg';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import Footer from './Footer';


export default function AboutUs({ matches, value, setValue }) {
    return (
        <>
            {matches && <Header value={value} setValue={setValue} />}
            {!matches && <HeaderMobile value={value} setValue={setValue} />}
            <Stack sx={{ width: "100%", justifyContent: 'center', display: 'flex' }} direction="column">
                <Box
                    component="img"
                    alt='poza1'
                    src={patratele}
                    sx={{
                        height: matches ? '25rem' : 'auto',
                        width: matches ? 'auto' : '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '5rem',
                        display: 'flex'
                    }}
                />
                <Typography
                    variant='h6'
                    align="center"
                    sx={{
                        marginTop: '4rem',
                        color: '#444444',
                        maxWidth: "80%", // Prevents text from being too wide
                        paddingX: { xs: "1rem", sm: "2rem" }, // Adds horizontal padding
                        marginX: "auto", // Centers the text
                    }}
                >
                    <br />
                    We’re CrossChain Labs, a team of seasoned software developers with hands-on experience in blockchain services and dApp development.<br />
                    What sets us apart is our creativity and willingness to think outside the box. <br />We see opportunities where others see problems and come up with solutions that are both elegant and effective.<br />
                    <br />
                    Our partners:
                </Typography>
                <Stack
                    direction={matches ? "row" : 'column'}
                    sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        height: matches ? '10rem' : '20rem',
                    }}
                >
                    <Box
                        component="img"
                        alt='stellar'
                        src={stellar}
                        sx={{
                            height: '3rem',
                            marginRight: matches ? '2rem' : 0,
                            marginY: 'auto',
                        }}
                    />
                    <Box
                        component="img"
                        alt='near'
                        src={near}
                        sx={{
                            height: '6rem',
                            marginRight: matches ? '2rem' : 0,
                            marginY: 'auto',
                        }}
                    />
                    <Box
                        component="img"
                        alt='filecoin'
                        src={filecoin}
                        sx={{
                            height: '4rem',
                            marginRight: matches ? '2rem' : 0,
                            marginY: 'auto',
                        }}
                    />
                    <Box
                        component="img"
                        alt='dfinity'
                        src={dfinity}
                        sx={{
                            height: '4rem',
                            marginRight: matches ? '2.5rem' : 0,
                            marginY: 'auto',
                        }}
                    />
                    <Box
                        component="img"
                        alt='polkadot'
                        src={polkadot}
                        sx={{
                            height: '2.5rem',
                            marginY: 'auto',
                        }}
                    />
                </Stack>

                <div
                    style={{
                        background: `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(${hands}) no-repeat`,
                        // backgroundImage: `url(${hands})`,
                        // backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%',
                        height: '30rem',
                        marginBottom: '10rem',
                        marginTop: '5rem',
                        display: 'flex'
                    }}
                >
                    <Stack
                        direction='column'
                        sx={{
                            margin: 'auto',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#ffffff',
                                marginTop: '2rem'
                            }}
                            variant='h3'
                            align="center"
                        >
                            WHAT WE DO
                        </Typography>
                        <Typography
                            sx={{
                                color: '#ffffff',
                                marginTop: '3rem',
                                marginBottom: '3rem'
                            }}
                            align="center"
                        >
                            Our journey began with developing dApps for various blockchain organisations and<br />continued with bringing our best ideas to life.
                        </Typography>
                        <Button
                            variant='outlined'
                            disableRipple
                            href="https://github.com/CrossChainLabs/"
                            target="_blank"
                            sx={{
                                width: '11rem',
                                height: '3rem',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                borderRadius: 0,
                                borderColor: "#6ca4a4",
                                color: '#6ca4a4',
                                '&: hover': {
                                    color: '#fff',
                                    backgroundColor: '#6ca4a4',
                                    borderColor: "#6ca4a4",
                                },

                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12
                                }}
                            >
                                View More on Github
                            </Typography>
                        </Button>
                    </Stack>
                </div>

            </Stack>
            <Footer />
        </>
    )
}
