import React from 'react';

import {
    Avatar,
    Typography,
    Stack,
    Link
} from '@mui/material';

import pozaCriss from '../resources/Cristina.jpeg';
import pozaCata from '../resources/Cata.jpeg';
import pozaGeorge from '../resources/George.jpeg';
import pozaAndre from '../resources/Andreea.jpeg';
import linkedInLogo from "../resources/Linkedin.png"

import Header from './Header';
import HeaderMobile from './HeaderMobile';
import Footer from './Footer';


function Person({ avatarURL, nume, descriere1, descriere2, linkedinLink }) {
    return (
        <Stack
            direction="column"
            sx={{
                width: { xs: "100%", sm: "15rem" },
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <Avatar
                sx={{
                    width: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12.5rem" },
                    height: "auto",
                    marginBottom: "2rem",
                }}
                alt="profilePic"
                src={avatarURL}
            />
            <Link
                href={linkedinLink}
                target="_blank"
                underline="none"
                color="#6ca4a4"
                variant="h6"
            >
                {nume}
            </Link>
            {/* First line (h4) */}
            <Typography
                variant="body1"
                sx={{
                    marginY: "0.5rem",
                    color: "#444444",
                    maxWidth: "90%",
                }}
            >
                {descriere1}
            </Typography>
            {/* Second line (h5) */}
            <Typography
                variant="body1"
                sx={{
                    marginY: "0.5rem",
                    color: "#666666",
                    maxWidth: "90%",
                }}
            >
                {descriere2}
            </Typography>
            <Link
                href={linkedinLink}
                target="_blank"
                sx={{
                    marginTop: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img
                    src={linkedInLogo}
                    alt="LinkedIn"
                    style={{ width: "30px" }}
                />
            </Link>
        </Stack>
    );
}


export default function MeetTeam({ matches, value, setValue }) {

    return (
        <>
            {matches && <Header value={value} setValue={setValue} />}
            {!matches && <HeaderMobile value={value} setValue={setValue} />}

            <Stack sx={{ width: "100%", justifyContent: "center", display: "flex" }} direction="column">
                <Typography
                    variant="h3"
                    sx={{
                        marginTop: "5rem",
                        marginBottom: "3rem",
                        color: "#6ca4a4",
                        textAlign: "center",
                    }}
                >
                    MEET THE TEAM
                </Typography>
                <Typography
                    variant="h6"
                    align="center"
                    sx={{
                        marginBottom: "5rem",
                        maxWidth: "90%",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    We are CrossChain Labs, a team of experienced blockchain developers <br/> dedicated to transforming most innovative ideas into reality.
                </Typography>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    sx={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "5rem",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: { xs: 4, sm: 6, md: 8, lg: 10 },
                    }}
                >
                    <Person
                        avatarURL={pozaAndre}
                        nume="ANDREEA STEFAN"
                        descriere1="Co-founder & CEO"
                        descriere2="Former Senior Architect @ConsenSys"
                        linkedinLink="https://www.linkedin.com/in/andreea-stefan-66740b20/"
                    />
                    <Person
                        avatarURL={pozaGeorge}
                        nume="GEORGE ROBERT"
                        descriere1="Co-founder & CTO"
                        descriere2="Former Senior Engineer @ConsenSys"
                        linkedinLink="https://www.linkedin.com/in/george-robert-stefan-13385a9a/"
                    />
                    <Person
                        avatarURL={pozaCata}
                        nume="CATALIN VLAD"
                        descriere1="Software Engineer"
                        descriere2="Blockchain Enthusiast"
                        linkedinLink="https://www.linkedin.com/in/catalin-vlad-48b828229/"
                    />
                    <Person
                        avatarURL={pozaCriss}
                        nume="CRISTINA VARTENIUC"
                        descriere1="Software Engineer"
                        descriere2="UX/UI Driven"
                        linkedinLink="https://www.linkedin.com/in/cristina-varteniuc-6b3121224/"
                    />
                </Stack>
            </Stack>

            <Footer />
        </>
    );
}
