import React from 'react';

import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    Link,
    IconButton
} from '@mui/material';

import Header from './Header';
import HeaderMobile from './HeaderMobile';
import Footer from './Footer';
import tableta from "../resources/shutterstock_1646694286.jpg";
import linkedInLogo from "../resources/Linkedin.png"


export default function GetTouch({ matches, value, setValue }) {
    return (
        <>
            {matches && <Header value={value} setValue={setValue} />}
            {!matches && <HeaderMobile value={value} setValue={setValue} />}
            <Box
                sx={{
                    display: 'flex',
                    marginTop: matches ? '8rem' : '2rem',
                    marginBottom: '2rem',
                    // marginX: '2rem'
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        boxShadow: 0,
                        flexDirection: matches ? 'row' : 'column',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent
                            sx={{
                                flex: '1 0 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                width: matches ? '53rem' : '100%',
                                backgroundColor: '#f0f0f0',
                            }}
                        >
                            <Typography
                                variant="h3"
                                align='center'
                                sx={{
                                    marginTop: 'auto'
                                }}
                            >
                                GET IN TOUCH
                            </Typography>
                            <Link
                                underline='none'
                                align='center'
                                color="#000000"
                                sx={{
                                    marginX: 'auto',
                                    marginY: '2rem'
                                }}
                            >
                                team@crosschainlabs.tech
                            </Link>
                            <IconButton
                                disableRipple
                                href="https://www.linkedin.com/company/crosschain-labs/"
                                target="_blank"
                                sx={{
                                    marginBottom: 'auto'
                                }}
                            >
                                <img
                                    alt='linkedInLogo'
                                    src={linkedInLogo}
                                    style={{
                                        height: '2.5rem',
                                        width: "2.5rem",
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}

                                />
                            </IconButton>
                        </CardContent>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{
                            width: matches ? '55rem' : '100%',
                            height: matches ? '30rem' : '15rem ',
                        }}
                        image={tableta}
                        alt="tableta"
                    />
                </Card>
            </Box>
            <Footer />
        </>
    )
}
